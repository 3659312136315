import "./css/PrivacyPolicy.css";
const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <div className="logo">
        <img src="./Logo.png" />
        <img className="metaphy-icon" alt="" src="/metaphy.svg" />
      </div>
      <div className="metaphy-labs-parent">
        <div className="metaphy-labs">METAPHY LABS</div>
        <b className="privacy-policy1">Privacy Policy</b>
      </div>
      <div className="this-privacy-policy-container">
        <span className="this-privacy-policy-container1">
          <p className="this-privacy-policy">
            This Privacy Policy (the “Privacy Policy”) serves to inform you of
            our policies and procedures regarding the collection, use and
            disclosure of the information we receive when you access and use the
            website services, software, mobile applications, content, and all
            other products and services (“App”) owned, controlled or offered,
            directly or indirectly, by Metaphy Labs. (together with its
            subsidiaries and affiliates, “Metaphy Labs”, “we,” “our” or “us”).
            Metaphy Labs believes that the privacy of its users is paramount and
            strives to use Personal Information (as defined below) only in ways
            outlined in this Privacy Policy. The Privacy Policy is incorporated
            by reference into our Terms of Service, currently available at
            https://Tomobo.ai/terms-of-service.pdf (the “Terms of Service”). Any
            terms used herein and not defined will have the meanings given to
            them in the Terms of Service as the context requires. 
          </p>
          <p className="this-privacy-policy">
            By using the Services, you hereby warrant and represent that you
            have read, understand and agree to this Privacy Policy and the Terms
            of Service and that you are over 18 years of age. PLEASE DO NOT USE,
            INSTALL OR ACCESS THE SERVICES IF YOU DO NOT AGREE TO THIS PRIVACY
            POLICY.
          </p>
          <p className="this-privacy-policy">&nbsp;</p>
          <p className="this-privacy-policy">&nbsp;</p>
          <ul className="what-we-mean-by-personal-infor">
            <li className="information-you-provide-in-ord">
              <span className="what-we-mean">
                What We Mean by Personal Information
              </span>
            </li>
          </ul>
          <p className="this-privacy-policy">&nbsp;</p>
          <p className="this-privacy-policy">
            For purposes of this Privacy Policy, “Personal Information” means
            any information from or about a person that either identifies that
            person directly or that makes that person identifiable when it is
            combined with other information from or about that person from any
            source.
          </p>
          <p className="this-privacy-policy">&nbsp;</p>
          <p className="this-privacy-policy">&nbsp;</p>
          <ul className="what-we-mean-by-personal-infor">
            <li className="information-you-provide-in-ord">
              <span className="what-we-mean">Information We Collect</span>
            </li>
          </ul>
          <p className="this-privacy-policy">&nbsp;</p>
          <p className="information-you-provide">
            Information You Provide To Us
          </p>
          <p className="this-privacy-policy">&nbsp;</p>
          <p className="this-privacy-policy">
            When you use the Services or otherwise communicate with us, we
            collect information that you provide to us directly. For example, we
            collect information in the following circumstances: you 
          </p>
          <p className="this-privacy-policy">
            (i) use our mobile apps or visit the Site, 
          </p>
          <p className="this-privacy-policy">
            (ii) register for the Services or create an account or profile or
            accept the creation of an account or profile on your behalf (an
            “Account”), 
          </p>
          <p className="this-privacy-policy">
            (iii) access the Services through social networking sites or third
            party services, 
          </p>
          <p className="this-privacy-policy">(iv) subscribe to newsletters; </p>
          <p className="this-privacy-policy">
            (v) purchase a product or service on the Site or in our mobile
            apps; 
          </p>
          <p className="this-privacy-policy">
            (vi) invite others to use or join the Services, “share” the Services
            on social networking sites or perform other similar actions; 
          </p>
          <p className="this-privacy-policy">
            (vii) request technical support; 
          </p>
          <p className="this-privacy-policy">
            (viii) otherwise use our mobile apps or online Services where
            Personal Information is required for such use and/or participation,
          </p>
          <p className="this-privacy-policy">
             (ix) when you sign up for an automated email or other
            communication, and 
          </p>
          <p className="this-privacy-policy">
            (x) when you contact us via the Site or when you otherwise
            communicate with us. You can choose not to provide information that
            is requested of you by us; however, this may limit your ability to
            use or access the Services.
          </p>
          <p className="this-privacy-policy">&nbsp;</p>
          <p className="this-privacy-policy">
            The information you provide to us directly may include, without
            limitation, the following information that may, alone or in
            combination with other data, constitute Personal Information:
          </p>
          <p className="this-privacy-policy">&nbsp;</p>
          <ul className="what-we-mean-by-personal-infor">
            <li className="information-you-provide-in-ord">
              <span>
                Information you provide in connection with registering an
                Account, including your name, company name, e-mail, physical
                address, phone number, calendar data, meeting data, derivatives
                of such data, and any other information you decide to provide;
              </span>
            </li>
            <li className="information-you-provide-in-ord">
              <span>
                Information you provide in order to authenticate an Account,
                including your Account name, password, and any other information
                you decide to provide;
              </span>
            </li>
            <li className="information-you-provide-in-ord">
              <span>
                Information you provide in connection with completing a
                transaction or purchase using the Services;
              </span>
            </li>
            <li className="information-you-provide-in-ord">
              <span>
                Information you provide in connection with using the Services,
                including your name, company name, email address, physical
                address, phone number, and any other information you decide to
                provide;
              </span>
            </li>
            <li className="information-you-provide-in-ord">
              <span>
                Information you provide via email, through the “Contact” section
                on the Site or by using the contact details listed on various
                parts of the Site, including your name, company name, e-mail
                address, physical address, phone number, and any other
                information you decide to provide;
              </span>
            </li>
            <li className="information-you-provide-in-ord">
              <span>
                Information you provide in order to subscribe to our newsletters
                and updates, including your email address, the topic for which
                you wish to receive updates, or any other information you decide
                to provide us with. You may always unsubscribe from these emails
                by following the instructions included;
              </span>
            </li>
            <li className="information-you-provide-in-ord">
              <span>
                If you are one of our customers, suppliers or prospects, we may
                process limited Personal Information in the course of our
                business relation with you, for example when you place an order,
                request a demo or vice versa. Such Personal Information may
                include your name, company, title, e-mail address, phone number,
                address, order details, and where applicable and relevant,
                credit registrations and credit limits;
              </span>
            </li>
            <li className="information-you-provide-in-ord">
              <span>Transaction and limited (non-PCI) payment data;</span>
            </li>
            <li className="information-you-provide-in-ord">
              <span>
                Any other information you may want to share with us, such as
                Personal Information related to recruitment / job applications.
              </span>
            </li>
          </ul>
          <p className="this-privacy-policy">&nbsp;</p>
          <p className="this-privacy-policy">
            Moreover, if you contact us, a record of such correspondence may be
            kept.
          </p>
          <p className="this-privacy-policy">&nbsp;</p>
          <p className="this-privacy-policy">
            Additionally, if another user refers you to us by providing us with
            your e-mail address, we may send you a message. However, we will use
            your e-mail address only to send such other user’s messages unless
            you otherwise affirmatively provide your information to us.
          </p>
          <p className="this-privacy-policy">&nbsp;</p>
          <p className="this-privacy-policy">
            Automatically Collected Information
          </p>
          <p className="this-privacy-policy">&nbsp;</p>
          <p className="this-privacy-policy">
            Most of the data we collect in and through the Site and the Services
            is technical in nature and is collected and processed automatically
            through so-called application programming interfaces, software
            development kits, cookies and similar software-based technologies.
            Alone or in combination with other data, such automatically
            collected data may constitute Personal Information. The data we may
            collect by automated means may include, without limitation:
          </p>
          <p className="this-privacy-policy">&nbsp;</p>
          <ul className="what-we-mean-by-personal-infor">
            <li className="information-you-provide-in-ord">
              <span>
                Device data: including, but not limited to, data on device
                advertising IDs and similar hardware qualifiers, and precise
                location data.
              </span>
            </li>
            <li className="information-you-provide-in-ord">
              <span>
                Usage data: including, but not limited to, search terms entered
                and pages viewed.
              </span>
            </li>
            <li className="information-you-provide-in-ord">
              <span>
                 Network and internet information: including, but not limited
                to, URLs, Internet Protocol addresses, bounce rates, use of
                spoofing, active (TCP/IP) ports, number of sessions initiated,
                click streams, location information and network/Wi-Fi access
                points.
              </span>
            </li>
            <li className="information-you-provide-in-ord">
              <span>
                Information we collect on the use of the Site via cookies:
                please see the “How We Use Cookies and Other Technologies”
                section below for more information.
              </span>
            </li>
          </ul>
          <p className="this-privacy-policy">&nbsp;</p>
          <p className="this-privacy-policy">
            Google Analytics is an element of the Site. By using cookies, Google
            Analytics collects and stores data such as time of visit, pages
            visited, time spent on each page of the Site, the Internet Protocol
            address, and the type of operating system used in the devices used
            to access the Site. By using a browser plugin available at
            http://www.google.com/ads/preferences/plugin/ provided by Google,
            you can opt out of Google Analytics.
          </p>
          <p className="this-privacy-policy">&nbsp;</p>
          <p className="this-privacy-policy">
            Information You Post on the Services
          </p>
          <p className="this-privacy-policy">&nbsp;</p>
          <p className="this-privacy-policy">
            The Services may provide voice, video and text chat, forum or
            bulletin board tools to users and the ability to create an “avatar”
            or other customized profile and Account information. Information
            that you provide through the use of these tools will be available to
            the public generally. Metaphy Labs has no obligation to keep private
            any information that you disclose to other users or the public using
            these functions. You should exercise caution when deciding to
            disclose Personal Information through the use of these tools or
            functions. We strongly recommend that you do not post any
            information through the Services that allows strangers to identify
            or locate you or that you otherwise do not want to share with the
            public.
          </p>
          <p className="this-privacy-policy">&nbsp;</p>
          <p className="this-privacy-policy">
            How We Use Cookies and Other Technologies
          </p>
          <p className="this-privacy-policy">&nbsp;</p>
          <p className="this-privacy-policy">
            The Company may use various tracking technologies, such as cookies,
            web beacons, and local shared objects, on the Site. These
            technologies may collect information about your device, browsing
            habits, and usage of the Site, including the type of browser you
            use, your search preferences, and information about advertisements
            that have been displayed to you or that you have clicked on. This
            information enables us to continuously improve the Services.
            However, you have the option to disable some of these technologies
            through the settings on your device or browser, although this may
            affect your ability to use the Site fully. It is important to note
            that these technologies may include unique identifiers that can be
            stored on your device or in emails sent to you by us. We are
            committed to protecting your privacy and will only use this
            information in compliance with relevant laws and regulations.
          </p>
          <p className="this-privacy-policy">&nbsp;</p>
          <ul className="what-we-mean-by-personal-infor">
            <li className="information-you-provide-in-ord">
              <span className="what-we-mean">Use of Collected Information</span>
            </li>
          </ul>
          <p className="this-privacy-policy">&nbsp;</p>
          <p className="this-privacy-policy">
            We use the information you provide to us for the following
            purposes: 
          </p>
          <p className="this-privacy-policy">
            (i) to further our legitimate interests in providing the Services,
            including without limitation to collect and remit payment for paid
            features, 
          </p>
          <p className="this-privacy-policy">
            (ii) to administer your use of the Services and any Accounts you may
            have with us, 
          </p>
          <p className="this-privacy-policy">
            (iii) to personalize your experience, 
          </p>
          <p className="this-privacy-policy">
            (iv) to provide to you Service announcements or inform you of new
            releases and features, 
          </p>
          <p className="this-privacy-policy">
            (v) to provide you with further information and offers from us or
            third parties that we believe you may find useful or interesting,
            such as newsletters, marketing or promotional materials, (vi) to
            enforce our Terms of Service, 
          </p>
          <p className="this-privacy-policy">
            (vii) to resolve any disputes between users of the Site or between
            such users and us, 
          </p>
          <p className="this-privacy-policy">
            (viii) comply with a legal requirement or process, including, but
            not limited to, civil and criminal subpoenas, court orders or other
            compulsory disclosures; 
          </p>
          <p className="this-privacy-policy">
            (ix) to further our legitimate interest in protecting our rights,
            property, or safety and the rights, property and safety of the
            Services, our users or the public, and
          </p>
          <p className="this-privacy-policy">
            (x) to contact you to obtain feedback from you regarding the Site
            and the Services.
          </p>
          <p className="this-privacy-policy"> </p>
          <p className="this-privacy-policy">
            In addition to the purposes described above, we use the information
            collected automatically to 
          </p>
          <p className="this-privacy-policy">
            (i) to further our legitimate interests in monitoring and analyzing
            the use of the Services and for the technical administration of the
            Site,
          </p>
          <p className="this-privacy-policy">
             (ii) improve the Site and the Services, 
          </p>
          <p className="this-privacy-policy">
            (iii) generate and derive useful data and information concerning the
            interests, characteristics and website use behavior of our users,
            and 
          </p>
          <p className="this-privacy-policy">
            (iv) verify that users of the Services meet the criteria required to
            process their requests. 
          </p>
          <p className="this-privacy-policy">
            We may use demographic and geo-location, as well as information
            logged from your hardware or device, including data such as IP
            address, device model and ID, MAC address, operating system,
            application usage data, device time zone, region and language, and
            click ID, to ensure relevant advertising is presented within the
            Services.
          </p>
          <p className="this-privacy-policy">&nbsp;</p>
          <p className="this-privacy-policy">&nbsp;</p>
          <ul className="what-we-mean-by-personal-infor">
            <li className="information-you-provide-in-ord">
              <span className="what-we-mean">
                Third Parties We Share Personal Information With
              </span>
            </li>
          </ul>
          <p className="this-privacy-policy">&nbsp;</p>
          <p className="this-privacy-policy">
            We may disclose personal information that you provide to us or that
            we collect automatically on the Site and through our Services to the
            following categories of third parties, but only if it is necessary
            and in compliance with relevant laws and regulations:
          </p>
          <p className="this-privacy-policy">&nbsp;</p>
          <ul className="what-we-mean-by-personal-infor">
            <li className="information-you-provide-in-ord">
              <span>
                Service providers, such as payment processors, web hosting and
                data storage providers, and those who help us deliver and
                develop our Services.
              </span>
            </li>
            <li className="information-you-provide-in-ord">
              <span>
                Public authorities, such as law enforcement, if we are legally
                required to do so or if it is necessary to protect our rights or
                the rights of third parties.
              </span>
            </li>
            <li className="information-you-provide-in-ord">
              <span>
                Our subsidiaries and affiliates, or a subsequent owner, co-owner
                or operator of our Site and/or Services, and their advisors in
                connection with a corporate merger, consolidation,
                restructuring, the sale of substantially all of our stock and/or
                assets, or in connection with bankruptcy proceedings, or other
                corporate reorganization, in accordance with this Privacy
                Policy.
              </span>
            </li>
          </ul>
          <p className="this-privacy-policy">&nbsp;</p>
          <p className="this-privacy-policy">
            We are committed to protecting the privacy of our users and will
            only disclose personal information to third parties when it is
            necessary and in compliance with relevant laws and regulations. We
            will also implement strict contracts and data processing agreements
            with third parties to ensure that they are only using personal
            information for the specific purposes outlined by us, and that they
            are protecting the privacy and security of the personal information
            they receive.
          </p>
          <p className="this-privacy-policy">&nbsp;</p>
          <p className="this-privacy-policy">&nbsp;</p>
          <ul className="what-we-mean-by-personal-infor">
            <li className="information-you-provide-in-ord">
              <span className="what-we-mean">Links to Other Websites</span>
            </li>
          </ul>
          <p className="this-privacy-policy">&nbsp;</p>
          <p className="this-privacy-policy">
            From time to time, the Services may have links to sites outside of
            it. These sites may have links to offers and promotions from third
            parties. We put these in so that you can find information, products,
            or services that you might find helpful or interesting. We are not
            responsible for what is on these sites or what they offer, and we
            can't promise that they will always be up and running. Just because
            we have links to these other sites doesn't mean that we support or
            work with the people who run or promote them. The terms and
            conditions of use and privacy policies for any website controlled,
            owned, or run by a third party tell you how to use that website. You
            use these websites run by other people at your own risk. Metaphy
            Labs makes it clear that it is not responsible for anything that
            happens because you use or look at websites or other content linked
            from the Services. You agree to not hold Metaphy Labs responsible
            for anything that might happen if you click on a link on the
            Services.
          </p>
          <p className="this-privacy-policy">&nbsp;</p>
          <p className="this-privacy-policy">&nbsp;</p>
          <ul className="what-we-mean-by-personal-infor">
            <li className="information-you-provide-in-ord">
              <span className="what-we-mean">Security</span>
            </li>
          </ul>
          <p className="this-privacy-policy"> </p>
          <p className="this-privacy-policy">
            We understand the importance of privacy and security of Personal
            Information to our users and have made them a priority. Metaphy Labs
            uses a variety of industry-standard security technologies and
            procedures to help protect Personal Information about you from
            unauthorized access, use, or disclosure and trains Metaphy Labs
            employees on privacy and security issues. You can read more details
            about Metaphy Labs security at https://Tomobo.ai/security.
          </p>
          <p className="this-privacy-policy">&nbsp;</p>
          <p className="this-privacy-policy">&nbsp;</p>
          <ul className="what-we-mean-by-personal-infor">
            <li className="information-you-provide-in-ord">
              <span className="what-we-mean">
                Do Not Track Signals and Similar Mechanisms
              </span>
            </li>
          </ul>
          <p className="this-privacy-policy">&nbsp;</p>
          <p className="this-privacy-policy">
            Some web browsers transmit “do-not-track” signals to websites.
            Because of differences in how web browsers incorporate and activate
            this feature, it is not always clear whether users intend for these
            signals to be transmitted, or whether they even are aware of them.
            We currently do not take action in response to these signals.
          </p>
          <p className="this-privacy-policy">&nbsp;</p>
          <p className="this-privacy-policy">&nbsp;</p>
          <ul className="what-we-mean-by-personal-infor">
            <li className="information-you-provide-in-ord">
              <span className="what-we-mean">Managing Your Privacy</span>
            </li>
          </ul>
          <p className="this-privacy-policy">&nbsp;</p>
          <p className="this-privacy-policy">
            We keep your data on your behalf and for your benefit. You can
            correct or terminate and delete your Account information by
            following the instructions on the Services or by email at
            support@Tomobo.ai, which you may also access for further assistance
            and questions regarding the Privacy Policy or for a copy of your
            Account data. If you cancel your Account or request us to delete
            your information, Metaphy Labs will limit its access to Personal
            Information to perform what is requested by you and will delete
            information accessible to Metaphy Labs within seven business days.
          </p>
          <p className="this-privacy-policy">&nbsp;</p>
          <p className="this-privacy-policy">
            For certain of its services, Metaphy Labs may employ Artificial
            Intelligence (AI) or other similar technologies, which may include
            the processing of user data. Metaphy Labs will take reasonable means
            to preserve the privacy and security of such data, but Metaphy Labs
            is not liable for any loss or harm resulting from the user's use of
            AI or similar technologies. By utilizing Metaphy Labs' services, the
            user understands and accepts the risks involved with the use of AI
            or similar technologies and agrees to indemnify and hold Metaphy
            Labs harmless for any claims, damages, or losses resulting from such
            usage.
          </p>
          <p className="this-privacy-policy">&nbsp;</p>
          <p className="this-privacy-policy">
            In addition, Metaphy Labs provides private cloud services to satisfy
            the demands of consumers or businesses who want their customer data
            to be strictly isolated. Private cloud customers have greater
            control over their underlying infrastructure, allowing them to
            customise it to their own business and regulatory needs. Private
            cloud customers that want their data to be strictly segregated (for
            example, customers dealing with PHI) can choose the Private Cloud
            option to exercise complete control over their compute and data
            infrastructure, ensuring that their data is separated per their
            compliance requirements.
          </p>
          <p className="this-privacy-policy">&nbsp;</p>
          <p className="this-privacy-policy">&nbsp;</p>
          <ul className="what-we-mean-by-personal-infor">
            <li className="information-you-provide-in-ord">
              <span className="what-we-mean">Your Rights</span>
            </li>
          </ul>
          <p className="this-privacy-policy">&nbsp;</p>
          <p className="this-privacy-policy">
            We take steps to help ensure that you are able to exercise your
            rights regarding Personal Information about you in accordance with
            applicable law. If you would like to access, review, erase, withdraw
            your consent, or object to, or restrict the processing of Personal
            Data we collect about you, you may submit a request to
            support@Tomobo.ai. We will promptly review all such requests in
            accordance with applicable laws. Depending on where you live, you
            may also have a right to lodge a complaint with a supervisory
            authority or other regulatory agency if you believe that we have
            violated any of the rights concerning Personal Information about
            you. We encourage you to first reach out to us at support@Tomobo.ai,
            so we have an opportunity to address your concerns directly before
            you do so.
          </p>
          <p className="this-privacy-policy">&nbsp;</p>
          <ul className="what-we-mean-by-personal-infor">
            <li className="information-you-provide-in-ord">
              <span className="what-we-mean">
                International Transfers of Personal Information
              </span>
            </li>
          </ul>
          <p className="this-privacy-policy">&nbsp;</p>
          <p className="this-privacy-policy">
            Metaphy Labs is based in India. As a result, Personal Information
            that we collect in and through the Services and on the Site may be
            transferred to our Indian offices. In addition, we may work with
            third-party service providers in other countries to support our
            business activities. Thus, Personal Information may be transferred
            to, stored on servers in, and accessed from the
          </p>
          <p className="this-privacy-policy">
            countries other than the country in which the Personal Information
            was initially collected. In all such instances, we use, transfer,
            and disclose Personal Information solely for the purposes described
            in this Privacy Notice and in compliance with applicable laws.
          </p>
          <p className="this-privacy-policy">&nbsp;</p>
          <ul className="what-we-mean-by-personal-infor">
            <li className="information-you-provide-in-ord">
              <span className="what-we-mean">Data Retention</span>
            </li>
          </ul>
          <p className="this-privacy-policy">&nbsp;</p>
          <p className="this-privacy-policy">
            We will delete your Personal Information when it is no longer needed
            for the purposes for which it was collected, or when you request us
            to do so. Please note that we may be required by law or other legal
            obligations to retain some of your Personal Information, even after
            your request for deletion. However, once those requirements are no
            longer in place, we will promptly delete your Personal Information
            in accordance with your request. We are committed to protecting your
            privacy and ensuring that your personal information is treated with
            the utmost care and respect.
          </p>
          <p className="this-privacy-policy">&nbsp;</p>
          <p className="this-privacy-policy">&nbsp;</p>
          <ul className="what-we-mean-by-personal-infor">
            <li className="information-you-provide-in-ord">
              <span className="what-we-mean">
                Changes to the Privacy Policy
              </span>
            </li>
          </ul>
          <p className="this-privacy-policy">&nbsp;</p>
          <p className="this-privacy-policy">
            Our security and Privacy Policy are periodically reviewed and
            enhanced as necessary. This Privacy Policy might change as we update
            and expand the Services. You can tell when this Privacy Policy was
            last updated by reviewing the Last Updated-legend on top of this
            page. We will endeavor to notify you of these changes by email, but
            will not be liable for any failure to do so. We also encourage you
            to review this Privacy Policy periodically. If you do not understand
            any of the terms or conditions of any of our policies, you may
            inquire regarding the same via email at support@Tomobo.ai. Your
            continued use of the Services after any change in this Privacy
            Policy will constitute your acceptance of such change.
          </p>
          <p className="this-privacy-policy">&nbsp;</p>
          <p className="this-privacy-policy">&nbsp;</p>
          <ul className="what-we-mean-by-personal-infor">
            <li className="information-you-provide-in-ord">
              <span className="what-we-mean">Contacting Us</span>
            </li>
          </ul>
          <p className="this-privacy-policy">&nbsp;</p>
          <p className="this-privacy-policy">
            If you have any concerns or questions about this Privacy Policy,
            please contact us at support@Tomobo.ai.
          </p>
        </span>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
