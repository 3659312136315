import React, { useState } from "react";
import "./textfield.css";

const TextField = ({ onTextChange }) => {
  const [text, setText] = useState("");

  const handleChange = (event) => {
    const { value } = event.target;
    setText(value);
    onTextChange(value); // Call the callback function from the parent component
  };

  const wordCount = text.trim().split(/\s+/).length;

  return (
    <div className="large-text-field-container">
      <textarea
        className="large-text-field"
        placeholder="Let us know more..."
        value={text}
        onChange={handleChange}
        maxLength={500}
      >
      </textarea>
      {/* <span className="required-star">*</span> */}

      <div className="word-counter">{`${wordCount}/500`}</div>
    </div>
  );
};

export default TextField;
