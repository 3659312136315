import React from "react";
import "./css/Failure.css";
function Failure() {
  const handleClick = () => {
    window.location.href = "mailto:vivek@metaphy.world";
  };
  return (
    <div className="container-failure">
      <img src="Logo.png" />
      <div className="container-failure-child">
        <h1>
          Hmm... Looks like some gremlins are not letting us connect to your
          calendar
        </h1>
        <button onClick={handleClick}>Reach us here </button>
        <img src="./failure.png" />
      </div>
    </div>
  );
}

export default Failure;
