import React from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Failure from "./pages/Failure";
// import CSAT11Desktop from "./pages/CSAT11Desktop";
// import CSAT1Desktop from "./pages/CSAT1Desktop";
import Success from "./pages/Success";
import Landing from "./pages/Landing";
import Error from "./pages/Error";
import { useEffect } from "react";
import { isUserAuthenticatedState } from "./atom";
import { useRecoilState } from "recoil";
import TermsAndConditions from "./pages/TermsAndServices";
import Loader from "./pages/Loading";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import CSat from "./pages/CSat";
import Thankyou from "./pages/Thankyou";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;
  const [isUserAuthenticated] = useRecoilState(isUserAuthenticatedState);
  const accessToken = localStorage.getItem("token");
  const navigate = useNavigate(); // Use the useNavigate hook

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
      case "/csat-11-desktop":
        title = "";
        metaDescription = "";
        break;
      case "/csat-1-desktop":
        title = "";
        metaDescription = "";
        break;
      case "/success-desktop":
        title = "";
        metaDescription = "";
        break;
      case "/connect-calendar-desktop":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]',
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  useEffect(() => {
    if (isUserAuthenticated || accessToken) {
      if (pathname === "/") {
        window.location.href = "/success";
      }
    }
    // else{

    //   const urlParams = new URLSearchParams(window.location.search);
    //   const statusCode = urlParams.get('statusCode');
    //   console.log(statusCode)
    //   if (statusCode === "401" || statusCode === "404" || statusCode ==='502' || statusCode ==='500') {
    //     navigate("/failure");
    //   }

    // }
  }, [isUserAuthenticated, accessToken, navigate, pathname]);

  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/failure" element={<Failure />} />
      <Route path="/tnc" element={<TermsAndConditions />} />
      <Route path="/pp" element={<PrivacyPolicy />} />
      <Route path="/error" element={<Error />} />
      {/* <Route path="/loader" element={<Loader />} /> */}
      <Route path="/success" element={<Success />} />
      <Route path="/csat" element={<CSat />} />
      <Route path="/greet" element={<Thankyou />} />
    </Routes>
  );
}

export default App;
