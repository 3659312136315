import React from "react";
import { useState } from "react";
import "./css/Success.css";
const Success = () => {
  const handleButtonClick = () => {
    // Get the window object that you want to close.
    const windowToClose = window.opener;
    console.log(windowToClose);
    // If the window object exists, close it.
    if (windowToClose) {
      windowToClose.close();
    }
  };

  const buttonClassName = ` cursor-pointer relative tracking-[0.15px] leading-[24px] font-semibold text-mediumslateblue`;

  // CSS style for hover effect
  const hoverStyle = `
  &:hover {
    background-color: purple;
    color: white;
  }
`;

  // Combine the base class and hover style
  const combinedButtonClassName = `${buttonClassName} ${hoverStyle}`;

  return (
    <div className="container-success">
      <div>
        <img src="./Logo.png" />
      </div>
      <div className="container-success-child">
        <div className="container-success-text">
          <h1>
            That’s it! You’re ready to <span className="tomobo">ToMoBo!</span>{" "}
          </h1>
          <p>
            Take a deep breath and relax ❤️ ToMoBo will join your meetings as
            and when you schedule one.
          </p>
        </div>
        {/* <button onClick={handleButtonClick}>Close this window</button> */}
        <img
          className="rocket"
          src="./blowing-rocket-3d-cartoon-style-iconremovebgpreview-1@2x.svg"
        />
      </div>
    </div>
  );
};

export default Success;
