import React from "react";
import { useState } from "react";
import "./css/Thankyou.css";
const Success = () => {
  const handleButtonClick = () => {
    // Get the window object that you want to close.
    const windowToClose = window.opener;
    console.log(windowToClose);
    // If the window object exists, close it.
    if (windowToClose) {
      windowToClose.close();
    }
  };

  const buttonClassName = ` cursor-pointer relative tracking-[0.15px] leading-[24px] font-semibold text-mediumslateblue`;

  // CSS style for hover effect
  const hoverStyle = `
  &:hover {
    background-color: purple;
    color: white;
  }
`;


  return (
    <div className="container-thankyou">
      <div>
        <img src="./Logo.png" />
      </div>
      <div className="container-thankyou-child">
        <img className="rocket" src="./robot.png" />
        <div className="container-thankyou-text">
          <h1>Thanks! We really appreciate this! </h1>
        </div>
      </div>
    </div>
  );
};

export default Success;
