import "./css/TermsAndConditions.css";
const TermsAndConditions = () => {
  return (
    <div className="terms-and-conditions">
      <div className="logo1">
        <div className="graphic2">
          <div className="main-graphic1">
            <div className="graphic3">
              <div className="graphic-child7" />
              <div className="graphic-child8" />
              <div className="graphic-child9" />
              <div className="graphic-child10" />
              <div className="graphic-child11" />
              <div className="graphic-child12" />
              <div className="graphic-child13" />
              <div className="graphic-child14" />
              <div className="graphic-child15" />
              <div className="graphic-child16" />
              <div className="graphic-child17" />
            </div>
          </div>
        </div>
        <img className="metaphy-icon1" alt="" src="/metaphy1.svg" />
      </div>
      <div className="agreement-parent">
        <div className="agreement">AGREEMENT</div>
        <b className="terms-of-service">Terms of Service</b>
      </div>
      <div className="these-terms-of-container">
        <span className="these-terms-of-container1">
          <p className="these-terms-of">
            These Terms of Service constitute a legally binding agreement
            between you and Metaphy Labs Private Limited  (together with its
            affiliates, “Metaphy Labs”, “we,” “our” or “us”) governing your use
            of our products, services, website, mobile application (the
            “App”),. 
          </p>
          <p className="these-terms-of">&nbsp;</p>
          <p className="these-terms-of">
            YOU ACKNOWLEDGE AND AGREE THAT, BY CLICKING ON THE “I AGREE” OR
            SIMILAR BUTTON, REGISTERING FOR AN ACCOUNT, DOWNLOADING THE APP OR
            ANY APP UPGRADES, USING THE APP ON YOUR MOBILE DEVICE, OR ACCESSING
            OR USING THE SERVICES, YOU ARE INDICATING THAT YOU HAVE READ,
            UNDERSTAND AND AGREE TO BE BOUND BY THESE TERMS OF SERVICE, WHETHER
            OR NOT YOU HAVE REGISTERED WITH THE SITE OR THE APP. IF YOU DO NOT
            AGREE TO THESE TERMS OF SERVICE, THEN YOU HAVE NO RIGHT TO ACCESS OR
            USE THE SERVICES. 
          </p>
          <p className="these-terms-of">&nbsp;</p>
          <p className="these-terms-of">
            These Terms of Service are effective as of the date you first click
            “I agree” (or similar button or checkbox) or use or access the
            Services, whichever is earlier. If you accept or agree to these
            Terms of Service on behalf of your employer or another legal entity,
            you represent and warrant that (i) you have full legal authority to
            bind your employer or such entity to these Terms of Services; (ii)
            you have read and understand these Terms of Service; and (iii) you
            agree to these Terms of Service on behalf of the party that you
            represent and any permitted users of such party. In such an event,
            “you” and “your” will refer and apply to your employer or such other
            legal entity. 
          </p>
          <p className="these-terms-of">&nbsp;</p>
          <p className="these-terms-of">
            Any personal data you submit to us or which we collect about you is
            governed by our Privacy Policy (“ Privacy Policy”), available at
            https://tomobo.ai/privacy_policy.pdf. You acknowledge that by using
            the Services, you have reviewed the Privacy Policy. The Privacy
            Policy is incorporated by reference into these Terms of Service and
            together form and are hereinafter referred to as this “Agreement.” 
          </p>
          <p className="these-terms-of">&nbsp;</p>
          <p className="these-terms-of">
            PLEASE NOTE: THIS AGREEMENT GOVERNS HOW DISPUTES BETWEEN YOU AND
            Metaphy Labs CAN BE RESOLVED. IT CONTAINS A BINDING AND FINAL
            ARBITRATION PROVISION AND CLASS ACTION WAIVER (SECTION 14). PLEASE
            READ CAREFULLY AS IT AFFECTS YOUR LEGAL RIGHTS, INCLUDING, IF
            APPLICABLE, YOUR RIGHT TO OPT OUT OF ARBITRATION. 
          </p>
          <p className="these-terms-of">&nbsp;</p>
          <p className="these-terms-of">&nbsp;</p>
          <p className="our-services">1. Our Services </p>
          <p className="these-terms-of">
            <b>&nbsp;</b>
          </p>
          <p className="these-terms-of">
            We provide a platform that can record, transcribe, summarize,
            analyze, and take action on conversations, helping users easily
            share their conversations and automate their work (“Services”).
          </p>
          <p className="these-terms-of"> </p>
          <p className="these-terms-of">&nbsp;</p>
          <p className="our-services">
            2. Account, Password, Security, and Mobile Phone Use 
          </p>
          <p className="our-services">&nbsp;</p>
          <p className="these-terms-of">
            You must register with Metaphy Labs and create an account to use the
            Services (an “Account”) and as part of that process you will be
            requested to provide certain information, including without
            limitation your name, and email address. By using the Services, you
            agree to provide true, accurate, current and complete information as
            prompted by the registration process and to maintain and promptly
            update the Account information to keep it accurate, current and
            complete. You are the sole authorized user of your Account. You are
            responsible for maintaining the confidentiality of any log-in,
            password, and Account number provided by you or given to you by
            Metaphy Labs for accessing the service. You are solely and fully
            responsible for all activities that occur under your password or
            Account. Metaphy Labs has no control over the use of any user’s
            Account and expressly disclaims any liability derived therefrom.
            Should you suspect that any unauthorized party may be using your
            password or Account or you suspect any other breach of security, you
            agree to contact Metaphy Labs immediately. 
          </p>
          <p className="these-terms-of">
            The person signing up for the Services will be the contracting party
            (“Account Owner”) for the purposes of these Terms of Service and
            will be the person who is authorized to use any corresponding
            Account we provide to the Account Owner in connection with the
            Services; provided, however, that if you are signing up for the
            Services on behalf of your employer, your employer shall be the
            Account Owner. As the Account Owner, you are solely responsible for
            complying with these Terms of Service and only you are entitled to
            all benefits accruing thereto. Your Account is not transferable to
            any other person or account. You must immediately notify us of any
            unauthorized use of your password or identification or any other
            breach or threatened breach of our security or the security of your
            Account. 
          </p>
          <p className="these-terms-of">&nbsp;</p>
          <p className="these-terms-of">&nbsp;</p>
          <p className="our-services">4. User Content </p>
          <p className="our-services">&nbsp;</p>
          <p className="these-terms-of">
            “User Content” is defined as any content, information, and materials
            that may be textual, audio, or visual that you provide, submit,
            upload, publish, or make otherwise available to the Services and
            other users. You are the only one who is in charge of User Content.
            You agree that you are the only one responsible for the User Content
            you send, transmit, display, or upload while using the Services. You
            are also responsible for following all laws that apply to the User
            Content, including, but not limited to, any laws that require you to
            get permission from a third party to use the User Content and to
            give proper notices of third-party rights. You promise and guarantee
            that you have the right to upload the User Content to the Services
            and that doing so does not violate or infringe on the rights of any
            third party. Under no circumstances will Metaphy Labs be responsible
            for (a) User Content that is sent or viewed while using the
            Services, (b) errors or omissions in the User Content, or (c) any
            loss or damage of any kind caused by the authorised use of, access
            to, or denial of access to User Content. Metaphy Labs isn't
            responsible for any User Content, but it has the right to delete any
            User Content at any time without notice if it breaks any of the
            rules in this agreement or the law. You keep the right to copy User
            Content and any other rights you already have. 
          </p>
          <p className="these-terms-of">&nbsp;</p>
          <p className="these-terms-of">
            Metaphy Labs is a passive conduit for your online distribution and
            publication of your User Content. You acknowledge and agree that
            Metaphy Labs: 
          </p>
          <ul className="is-not-involved-in-the-creatio">
            <li className="disclaims-any-responsibility-f">
              <span>
                Is not involved in the creation or development of User Content. 
              </span>
            </li>
            <li className="disclaims-any-responsibility-f">
              <span>Disclaims any responsibility for User Content. </span>
            </li>
            <li className="disclaims-any-responsibility-f">
              <span>
                Cannot be liable for claims arising out of or relating to User
                Content. 
              </span>
            </li>
            <li className="disclaims-any-responsibility-f">
              <span>
                Is not obligated to monitor, review, or remove User Content, but
                reserves the right to limit or remove User Content on the
                Services at its sole discretion. 
              </span>
            </li>
          </ul>
          <p className="these-terms-of">&nbsp;</p>
          <p className="these-terms-of">
            You hereby represent and warrant to Metaphy Labs that your User
            Content 
          </p>
          <p className="these-terms-of">
            (i) will not be false, inaccurate, incomplete or misleading; 
          </p>
          <p className="these-terms-of">
            (ii) will not infringe on any third party’s copyright, patent,
            trademark, trade secret or other proprietary right or rights of
            publicity, personality or privacy; 
          </p>
          <p className="these-terms-of">
            (iii) will not violate any law, statute, ordinance, or regulation
            (including without limitation those governing export control,
            consumer protection, unfair competition, anti-discrimination, false
            advertising, anti-spam or privacy); 
          </p>
          <p className="these-terms-of">
            (iv) will not be defamatory, libelous, unlawfully threatening, or
            unlawfully harassing; 
          </p>
          <p className="these-terms-of">
            (v) will not be obscene or contain pornography (including but not
            limited to child pornography) or be harmful to minors; 
          </p>
          <p className="these-terms-of">
            (vi) will not facilitate human trafficking; 
          </p>
          <p className="these-terms-of">
            (vii) will not support terrorism or terrorist organizations; 
          </p>
          <p className="these-terms-of">
            (viii) will not be fraudulent, false or misleading; 
          </p>
          <p className="these-terms-of">
            (ix) will not be defamatory, harassing, threatening or abusive,
            which includes any activity that reflects hatred against others
            based on race, religion, ethnicity, national origin, gender or
            sexual orientation; 
          </p>
          <p className="these-terms-of">
            (x) will not send unauthorized messages, advertising or spam,
            including unsolicited promotional or commercial content or other
            mass solicitation materials; (xi) will not misrepresent your
            identity or affiliation with any entity or organization, or
            impersonate any other person; 
          </p>
          <p className="these-terms-of">
            (xii) will not harvest, collect or gather user data without
            consents; 
          </p>
          <p className="these-terms-of">
            (xiii) will not contain any viruses, Trojan Horses, worms, time
            bombs, cancelbots or other computer programming routines that are
            intended to damage, detrimentally interfere with, surreptitiously
            intercept or expropriate any system, data or personal information; 
          </p>
          <p className="these-terms-of">
            (xiv) will not represent you being employed or directly engaged by
            or affiliated with Metaphy Labs or purport you to act as a
            representative or agent of Metaphy Labs; and 
          </p>
          <p className="these-terms-of">
            (xv) will not create liability for Metaphy Labs or cause Metaphy
            Labs to lose (in whole or in part) the services of its ISPs or other
            suppliers. 
          </p>
          <p className="these-terms-of">
            You are responsible for compliance with all recording laws. You may
            choose to record certain meetings in Metaphy Labs. By using the
            Services, you are giving Metaphy Labs consent to store recordings
            for any or all Metaphy Labs meetings or webinars that you join, if
            such recordings are stored in Metaphy Labs’ systems. You will
            receive a notification (visual or otherwise) when recording is
            enabled. If you do not consent to being recorded, you can choose to
            leave the meeting. 
          </p>
          <p className="these-terms-of">&nbsp;</p>
          <p className="our-services">5. Representations and Warranties </p>
          <p className="our-services">&nbsp;</p>
          <p className="these-terms-of">You represent and warrant that: </p>
          <p className="these-terms-of">
            (i) you are 18 years of age or older or are at least of the legally
            required age in the jurisdiction in which you reside, and are
            otherwise capable of entering into binding contracts, and 
          </p>
          <p className="these-terms-of">
            (ii) you have the right, authority and capacity to enter into this
            Agreement and to abide by the terms and conditions of this
            Agreement, and that you will so abide. When you enter into this
            Agreement on behalf of a company or other organization, you
            represent and warrant that you have authority to act on behalf of
            that entity and to bind that entity to this Agreement. 
          </p>
          <p className="these-terms-of">&nbsp;</p>
          <p className="these-terms-of">
            You further represent and warrant that 
          </p>
          <p className="these-terms-of">
            (i) you have read, understand, and agree to be bound by these Terms
            of Service and the Privacy Policy in order to access and use the
            Services, 
          </p>
          <p className="these-terms-of">
            (ii) you will act professionally and responsibly in your
            interactions with other users, and 
          </p>
          <p className="these-terms-of">
            (iii) when using or accessing the Services, you will act in
            accordance with any applicable local, state, or federal law or
            custom and in good faith. 
          </p>
          <p className="these-terms-of">&nbsp;</p>
          <p className="these-terms-of">
            You agree not to engage in any of the following prohibited
            activities, among others: 
          </p>
          <p className="these-terms-of">
            (i) copying, distributing, or disclosing any part of the Services in
            any medium other than as allowed by the Services and these Terms of
            Service; 
          </p>
          <p className="these-terms-of">
            (ii) using any automated system (other than any functionalities of
            the Services), including without limitation “robots,” “spiders,”
            “offline readers,” etc., to access the Services; 
          </p>
          <p className="these-terms-of">
            (iii) transmitting spam, chain letters, or other unsolicited email
            or attempting to phish, pharm, pretext, spider, crawl, or scrape; 
          </p>
          <p className="these-terms-of">
            (iv) attempting to interfere with, compromise the system integrity
            or security or decipher any transmissions to or from the servers
            running the Services; 
          </p>
          <p className="these-terms-of">
            (v) violating any international, federal, provincial or state
            regulations, rules, laws, or local ordinances; 
          </p>
          <p className="these-terms-of">
            (vi) conducting any unlawful purposes or soliciting others to
            perform or participate in any unlawful acts; 
          </p>
          <p className="these-terms-of">
            (vii) uploading invalid data, viruses, worms, or other software
            agents through the Services; (viii) infringing upon or violate our
            intellectual property rights or the intellectual property rights of
            others; 
          </p>
          <p className="these-terms-of">
            (ix) impersonating another person or otherwise misrepresenting your
            affiliation with a person or entity, conducting fraud, hiding or
            attempting to hide your identity; 
          </p>
          <p className="these-terms-of">
            (x) harassing, insulting, harming, abusing, defaming, abusing,
            harassing, stalking, threatening, intimidating or otherwise
            violating the legal rights (such as of privacy and publicity) of any
            other users or visitors of the Services or staff member of Metaphy
            Labs; 
          </p>
          <p className="these-terms-of">
            (xi) interfering with or any activity that threatens the
            performance, security or proper functioning of the Services; 
          </p>
          <p className="these-terms-of">
            (xii) uploading or transmitting viruses or any other type of
            malicious code; 
          </p>
          <p className="these-terms-of">
            (xiii) attempting to decipher, decompile, disassemble or reverse
            engineer any of the software or algorithms used to provide the
            Services; 
          </p>
          <p className="these-terms-of">
            (xiv) bypassing the security features or measures we may use to
            prevent or restrict access to the Services, including without
            limitation features that prevent or restrict use or copying of any
            content or enforce limitations on use of the Services or the content
            therein; 
          </p>
          <p className="these-terms-of">
            (xv) attempting to access unauthorized Accounts or to collect or
            track the personal information of others; 
          </p>
          <p className="these-terms-of">
            (xvi) using the Services for any purpose or in any manner that
            infringes the rights of any third party; or 
          </p>
          <p className="these-terms-of">
            (xvii) encouraging or enabling any other individual to do any of the
            foregoing. 
          </p>
          <p className="these-terms-of">&nbsp;</p>
          <p className="these-terms-of">
            You hereby warrant and represent that, other than as fully and
            promptly disclosed to Metaphy Labs as set forth below, you do not
            have any motivation, status, or interest which Metaphy Labs may
            reasonably wish to know about in connection with the Services,
            including without limitation, if you are using or will or intend to
            use the Services for any journalistic, investigative, or unlawful
            purpose. You hereby warrant and represent that you will promptly
            disclose to Metaphy Labs in writing any such motivation, status or
            interest, whether existing prior to registration or as arises during
            your use of the Services. 
          </p>
          <p className="these-terms-of">&nbsp;</p>
          <p className="our-services">6. Technology services </p>
          <p className="our-services">&nbsp;</p>
          <p className="these-terms-of">Artificial Intelligence services </p>
          <p className="these-terms-of">
            For certain services, Metaphy Labs may employ Artificial
            Intelligence (AI) or other similar technologies, which may include
            the processing of user data. Metaphy Labs will take reasonable means
            to preserve the privacy and security of such data, but Metaphy Labs
            is not liable for any loss or harm resulting from the user's use of
            AI or similar technologies. By utilizing Metaphy Labs' services, the
            user understands and accepts the risks involved with the use of AI
            or similar technologies and agrees to indemnify and hold Metaphy
            Labs harmless for any claims, damages, or losses resulting from such
            usage. 
          </p>
          <p className="these-terms-of">&nbsp;</p>
          <p className="these-terms-of">Private Cloud services </p>
          <p className="these-terms-of">
            The Private Cloud user acknowledges and agrees that all Personal
            User Information (PII), Protected Health Information (PHI), User
            Content, Organizational Strategies, and Confidential Information
            collected, accessed, or processed via the Metaphy Labs Private Cloud
            will be kept strictly confidential and protected. Metaphy Labs, as
            the supplier of the Private Cloud solution, shall only access and
            process the user's sensitive data with the user's express permission
            and solely for the agreed-upon services. The user is entirely
            responsible for maintaining the secrecy and security of its personal
            information and for adhering to all applicable data privacy and
            security legislation. 
          </p>
          <p className="these-terms-of">
            The Private Cloud user understands and accepts that he or she is
            solely responsible for the availability and uptime of the Metaphy
            Labs Private Cloud solution. The user is responsible for
            administering and monitoring the Private Cloud solution, including
            keeping adequate backups, disaster recovery plans, and security
            protocols. Metaphy Labs will provide the required infrastructure and
            assistance to ensure that the Private Cloud solution is operational.
            Metaphy Labs is not liable for any damages or losses resulting from
            outage or unavailability of the Private Cloud service as a result of
            inadequate management and monitoring by the user. 
          </p>
          <p className="these-terms-of">&nbsp;</p>
          <p className="these-terms-of">&nbsp;</p>
          <p className="our-services">7. Termination and Suspension </p>
          <p className="our-services">&nbsp;</p>
          <p className="these-terms-of">
            You may cancel and delete your Account at any time by either using
            the features on the Services to do so (if applicable and available)
            or by written notice to support@tomobo.ai. After cancellation your
            profile will be purged and, you will no longer have access to your
            Account, your profile or any other information through the Services.
            The provisions of these Terms of Service which by their intent or
            meaning intended to survive such termination, including without
            limitation the provisions relating to disclaimer of warranties,
            limitations of liability, and indemnification, shall survive any
            termination of these Terms of Service and any termination of your
            use of or subscription to the Services and shall continue to apply
            indefinitely. 
          </p>
          <p className="these-terms-of">&nbsp;</p>
          <p className="these-terms-of">
            At any time and for any reason, we have the right to deny access to
            the Services to anybody. If Metaphy Labs is investigating you or
            believes you have violated any of the terms of this Agreement, we
            may prevent you from accessing the Services or restrict how much of
            them you can use. We'll inform you in writing or by email. This
            notice of termination or limitation shall be effective immediately.
            You cannot register for and create a new Account in the name of a
            third party, a fake or borrowed name, or your own identity if
            Metaphy Labs terminates or restricts your ability to use the
            Services due to this section, even if you are acting on their
            behalf. 
          </p>
          <p className="these-terms-of">&nbsp;</p>
          <p className="these-terms-of">
            Even after your right to use the Services is terminated or limited,
            this Agreement will remain enforceable against you. Metaphy Labs
            reserves the right to take appropriate legal action, including but
            not limited to pursuing arbitration in accordance with Section 14 of
            these Terms of Service. 
          </p>
          <p className="these-terms-of">&nbsp;</p>
          <p className="these-terms-of">
            Metaphy Labs reserves the right to modify or discontinue,
            temporarily or permanently, all or any portion of the Services at
            its sole discretion. Metaphy Labs is not liable to you for any
            modification or discontinuance of all or any portion of the
            Services. Metaphy Labs has the right to restrict anyone from
            completing registration as a user if Metaphy Labs believes such a
            person may threaten the safety and integrity of the Services, or if,
            in Metaphy Labs’ discretion, such restriction is necessary to
            address any other reasonable business concern. 
          </p>
          <p className="these-terms-of">&nbsp;</p>
          <p className="these-terms-of">
            Following the termination or cancellation of your Account (as
            defined below), we reserve the right to delete all your data,
            including any User Content, in the normal course of operation. Your
            data cannot be recovered once your Account is terminated or
            canceled. 
          </p>
          <p className="these-terms-of">&nbsp;</p>
          <p className="these-terms-of">&nbsp;</p>
          <p className="our-services">8. Links to Third-Party Websites </p>
          <p className="these-terms-of">&nbsp;</p>
          <p className="these-terms-of">
            From time to time, the app may have links to any third-party
            websites. These websites may have links to offers and promotions
            from third parties. We put these in so that you can find
            information, products, or services that you might find helpful or
            interesting. We are not responsible for terms and conditions of use
            for those websites or what they offer, and we can't promise that
            they will always be up and running. Just because we have links to
            these other sites doesn't mean that we support or work with the
            people who run or promote them. 
          </p>
          <p className="these-terms-of">&nbsp;</p>
          <p className="these-terms-of">
            The terms and conditions of use and privacy policies for any website
            controlled, owned, or run by a third party tell you how to use that
            website. You use these websites run by other people at your own
            risk. Metaphy Labs makes it clear that it is not responsible for
            anything that happens because you use or look at websites or other
            content linked from the Services. You agree to not hold Metaphy Labs
            responsible for anything that might happen if you click on a link on
            the Services. As part of the way the Services work, you can link
            your Account with online accounts you may have with third-party
            service providers like Google (each such account, a "Third-Party
            Account") by either: I providing your Third-Party Account login
            information through the Services; or (ii) allowing Metaphy Labs to
            access your Third-Party Account, as permitted by the terms and
            conditions that govern your use of each Third-Party Account. You
            promise that you have the right to give Metaphy Labs your
            Third-Party Account login information and/or give Metaphy Labs
            access to your Third-Party Account, without breaking any of the
            terms and conditions that govern your use of the applicable
            Third-Party Account and without requiring Metaphy Labs to pay any
            fees or making Metaphy Labs subject to any usage limits set by such
            third-party service providers. By giving Metaphy Labs access to any
            Third-Party Accounts, you agree to the following: (1) Metaphy Labs
            may access, make available, and store (if applicable) any content
            that you have provided to and stored in your ThirdParty Account
            ("SNS Content") so that it is available on and through the Services
            via your Account, including, but not limited to, any friend lists;
            and (2) Metaphy Labs may send and receive additional information to
            your Third-Party Account to the extent you are no longer the account
            holder. Unless these Terms of Service say something different, all
            SNS content, if any, is considered to be User Content. Depending on
            the Third-Party Accounts you choose and the privacy settings you've
            made in those Third-Party Accounts, personally identifiable
            information that you post to your Third-Party Accounts may be
            available on and through your Account on the Services. Please note
            that SNS Content may no longer be available on and through the
            Services if a Third-Party Account or an associated service becomes
            unavailable or if Metaphy Labs' access to a Third-Party Account is
            cut off by the third-party service provider. As explained below, you
            will be able to turn off the link between your Account on the
            Services and your Third-Party Accounts at any time. PLEASE NOTE THAT
            YOUR RELATIONSHIP WITH THE THIRD-PARTY PROVIDERS ASSOCIATED WITH
            YOUR THIRD-PARTY ACCOUNTS IS GOVERNED SOLELY BY YOUR AGREEMENT(S)
            WITH SUCH THIRD PARTY PROVIDERS. Metaphy Labs doesn't try to check
            any SNS Content for accuracy, legality, or lack of infringement,
            among other things, and Metaphy Labs isn't responsible for any SNS
            Content. 
          </p>
          <p className="these-terms-of">&nbsp;</p>
          <p className="these-terms-of">&nbsp;</p>
          <p className="our-services">9. Intellectual Property Rights </p>
          <p className="our-services">&nbsp;</p>
          <p className="these-terms-of">
            Except for the Rights mentioned herein, all rights, title and
            interest in and to all the trademarks, copyrights, inventions, trade
            secrets, domain names, know-how and any other intellectual property
            and/or proprietary rights in or related to the App, or other
            Service(s), including the Website, and any part of it (collectively
            referred to as "Intellectual Property Rights") shall belong to and
            remain exclusively with Metaphy Labs.
          </p>
          <p className="these-terms-of">&nbsp;</p>
          <p className="these-terms-of">
            Metaphy Labs is the owner or the licensee of any/all Intellectual
            Property Rights in the Website, and the content or material
            published on it, including but not limited to text, graphics,
            articles, photographs, images, illustrations which works are
            protected by copyright laws. User shall not use any part of such
            content for commercial purposes without our/our licensor's prior
            consent vide a license to use. User shall abide by all copyright
            notices, trademark rules, information, and restrictions contained in
            any such content or data accessed through the Services, and shall
            not use, copy, reproduce, modify, translate, publish, broadcast,
            transmit, distribute, perform, upload, display, license, sell or
            otherwise exploit for any purposes whatsoever any such content or
            other proprietary rights not owned by you without the express prior
            written consent of Tomobo.ai
          </p>
          <p className="these-terms-of">&nbsp;</p>
          <p className="these-terms-of">
            The product and service names, and logos used or displayed on the
            Service(s) or Website are registered or unregistered trademarks
            which are intellectual property that belongs to Metaphy Labs and the
            User may only use such trademarks to identify itself as a user of
            the Service(s) which it has subscribed to.
          </p>
          <p className="these-terms-of">&nbsp;</p>
          <p className="these-terms-of">
            Additionally, you may choose to or we may invite you to submit
            comments, ideas, or feedback about the Services, including without
            limitation about how to improve our services or our products
            (“Feedback”). By submitting any Feedback, you agree that your
            disclosure is gratuitous, unsolicited, and without restriction and
            will not place Metaphy Labs under any fiduciary or other obligation,
            and that we are free to use the Feedback without any additional
            compensation to you, and/or to disclose the Feedback on a
            nonconfidential basis or otherwise to anyone. You further
            acknowledge that, by acceptance of your submission, Metaphy Labs
            does not waive any rights to use similar or related Feedback
            previously known to Metaphy Labs, developed by its employees, or
            obtained from sources other than you. You acknowledge that all email
            and other correspondence that you submit to us shall become our sole
            and exclusive property. 
          </p>
          <p className="these-terms-of">&nbsp;</p>
          <p className="these-terms-of">
            In addition, Metaphy Labs retains all rights to aggregated and
            anonymous data derived from your use of the Service, with the
            understanding that such data will not be identifiable as belonging
            to or emanating from you nor will such data contain information that
            directly or indirectly identifies you or any other person (natural
            or otherwise). 
          </p>
          <p className="these-terms-of">&nbsp;</p>
          <p className="these-terms-of">
            Subject to the terms and conditions hereof, you are hereby granted a
            limited, nonexclusive, nontransferable, freely revocable, right to
            access and use the Services. We may terminate this right at any time
            for any reason or no reason. The Services and all materials therein
            or transferred thereby, including, without limitation, software,
            images, text, graphics, illustrations, logos, patents, trademarks,
            service marks, reports generated by the Services, and copyrights
            (the “Metaphy Labs Content”), and all Intellectual Property Rights
            (as defined below) related thereto, are the exclusive property of
            Metaphy Labs or, as applicable, its licensors. Except as explicitly
            provided herein, nothing in this Agreement shall be deemed to create
            a license or other right in or under any such Intellectual Property
            Rights, and you agree not to sell, license, rent, modify, publicly
            distribute, publicly transmit, publicly display, publicly perform,
            publish, adapt, edit or create derivative works from any materials
            or content accessible on the Services. Use of the Metaphy Labs
            Content or materials on the Services for any purpose not expressly
            permitted by this Agreement is strictly prohibited. For the purposes
            of this Agreement, “Intellectual Property Rights” means all patent
            rights, copyright rights, mask work rights, moral rights, rights of
            publicity, trademark, trade dress and service mark rights, goodwill,
            trade secret rights and other intellectual property rights as may
            now exist or hereafter come into existence, and all applications
            therefore and registrations, renewals and extensions thereof, under
            the laws of any state, country, territory or other jurisdiction.
            Your use of the Services and the related licenses granted hereunder
            are also conditioned upon your strict adherence to the letter and
            spirit of the various applicable guidelines and any end user
            licenses associated with your use of the App. Metaphy Labs may
            modify such guidelines in its sole discretion at any time. Metaphy
            Labs reserves the right to terminate your Account and access to the
            Services if it determines that you have violated any such applicable
            guidelines. 
          </p>
          <p className="these-terms-of">&nbsp;</p>
          <p className="these-terms-of">&nbsp;</p>
          <p className="our-services">
            10. Copyright Complaints and Copyright Agent 
          </p>
          <p className="our-services">&nbsp;</p>
          <p className="these-terms-of">
            Metaphy Labs respects the intellectual property of others, and
            expects users to do the same. If you believe, in good faith, that
            any materials provided on or in connection with the Services
            infringe upon your copyright or other intellectual property right,
            please send the following information to Metaphy Labs’ Copyright
            Agent at support@tomobo.ai.
          </p>
          <p className="these-terms-of">
            ● A description of the copyrighted work that you claim has been
            infringed, including the URL (Internet address) or other specific
            location on the Services where the material you claim is infringed
            is located. Include enough information to allow Metaphy Labs to
            locate the material, and explain why you think an infringement has
            taken place; 
          </p>
          <ul className="is-not-involved-in-the-creatio">
            <li className="disclaims-any-responsibility-f">
              <span>
                A description of the location where the original or an
                authorized copy of the copyrighted work exists -- for example,
                the URL (Internet address) where it is posted or the name of the
                book in which it has been published; 
              </span>
            </li>
            <li className="disclaims-any-responsibility-f">
              <span>Your address, telephone number, and email address; </span>
            </li>
            <li className="disclaims-any-responsibility-f">
              <span>
                A statement by you that you have a good faith belief that the
                disputed use is not authorized by the copyright owner, its
                agent, or the law; 
              </span>
            </li>
            <li className="disclaims-any-responsibility-f">
              <span>
                A statement by you, made under penalty of perjury, that the
                information in your notice is accurate, and that you are the
                copyright owner or authorized to act on the copyright owner’s
                behalf; and 
              </span>
            </li>
            <li className="disclaims-any-responsibility-f">
              <span>
                An electronic or physical signature of the owner of the
                copyright or the person authorized to act on behalf of the owner
                of the copyright interest. 
              </span>
            </li>
          </ul>
          <p className="these-terms-of">&nbsp;</p>
          <p className="these-terms-of">&nbsp;</p>
          <p className="our-services">11. Confidential Information </p>
          <p className="these-terms-of">&nbsp;</p>
          <p className="these-terms-of">
            You acknowledge that Confidential Information (as defined below) is
            a valuable, special and unique asset of Metaphy Labs and agree that
            you will not disclose, transfer, use (or seek to induce others to
            disclose, transfer or use) any Confidential Information for any
            purpose other than using the Services in accordance with these Terms
            of Service. If relevant, you may disclose the Confidential
            Information to your authorized employees and agents provided that
            they are also bound to maintain the confidentiality of Confidential
            Information. You shall promptly notify Metaphy Labs in writing of
            any circumstances that may constitute unauthorized disclosure,
            transfer, or use of Confidential Information. You shall use best
            efforts to protect Confidential Information from unauthorized
            disclosure, transfer or use. You shall return all originals and any
            copies of any and all materials containing Confidential Information
            to Metaphy Labs upon termination of this Agreement for any reason
            whatsoever. 
          </p>
          <p className="these-terms-of">&nbsp;</p>
          <p className="these-terms-of">
            The term “Confidential Information” shall mean any and all of
            Metaphy Labs’ trade secrets, confidential and proprietary
            information, and all other information and data of Metaphy Labs that
            is not generally known to the public or other third parties who
            could derive value, economic or otherwise, from its use or
            disclosure. Confidential Information shall be deemed to include
            technical data, know-how, research, product plans, products,
            services, customers, markets, software, developments, inventions,
            processes, formulas, technology, designs, drawings, engineering,
            hardware configuration information, marketing, finances, strategic
            and other proprietary and confidential information relating to
            Metaphy Labs or Metaphy Labs’ business, operations or properties,
            including information about Metaphy Labs’ staff, users or partners,
            or other business information disclosed directly or indirectly in
            writing, orally or by drawings or observation. 
          </p>
          <p className="these-terms-of">&nbsp;</p>
          <p className="these-terms-of">&nbsp;</p>
          <p className="our-services">{`12. Disclaimer of Warranties `}</p>
          <p className="these-terms-of">&nbsp;</p>
          <p className="these-terms-of">
            WE DO NOT GIVE YOU ANY KIND OF WARRANTY, EITHER EXPRESS OR IMPLIED,
            ABOUT THE SERVICES WE GIVE YOU UNDER THIS AGREEMENT. THIS INCLUDES
            ANY IMPLIED WARRANTY OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR
            PURPOSE. WE DO NOT AND CANNOT PROMISE THAT METAPHY LABS SERVICES
            WILL WORK WITHOUT PROBLEMS OR THAT SOME OR ALL OF THEM WILL BE UP
            AND RUNNING ALL THE TIME. YOU AGREE THAT OUR OFFICERS, DIRECTORS,
            EMPLOYEES, AGENTS, OR CONTRACTORS ARE NOT RESPONSIBLE FOR ANY
            INDIRECT, INCIDENTAL, SPECIAL, OR CONSEQUENTIAL DAMAGES RELATED TO
            OR CAUSED BY ANY SERVICES AND PRODUCTS PROVIDED UNDER THIS AGREEMENT
            OR BY YOUR USE OF OR ACCESS TO THE APP, INCLUDING LOSS OF PROFITS,
            REVENUE, DATA, OR USE BY YOU OR ANY THIRD PARTY, WHETHER IN AN
            ACTION IN CONTRACT, TORT, OR OTHERWISE. IF, DESPITE THE ABOVE
            EXCLUSIONS, IT IS DETERMINED THAT METAPHY LABS AND AFFILIATES OR
            THEIR CORPORATE PARTNERS ARE LIABLE FOR DAMAGES, IN NO EVENT WILL
            THE AGGREGATE LIABILITY, WHETHER ARISING IN CONTRACT, TORT, STRICT
            LIABILITY OR OTHERWISE, EXCEED THE LESSER OF (I) THE TOTAL FEES YOU
            PAID BY YOU TO SUBSCRIBE TO METAPHY LABS DURING THE SIX MONTHS PRIOR
            TO THE TIME SUCH CLAIM AROSE OR (II) ONE HUNDRED DOLLARS ($100), TO
            THE EXTENT PERMITTED BY APPLICABLE LAW. 
          </p>
          <p className="these-terms-of">&nbsp;</p>
          <p className="our-services">13. INDEMNITY</p>
          <p className="our-services">&nbsp;</p>
          <p className="these-terms-of">
            User agrees to indemnify, defend, and hold harmless, Metaphy Labs
            and its respective officers, directors, employees, members,
            shareholders, or representatives (and all successors and assigns of
            any of the foregoing), from and against any claim or demand,
            liabilities, losses, actions, causes of action, costs and expenses,
            including but not limited to attorneys' fees, made by any third
            party in connection with or arising out of  (i) User's use of the
            service, or infringement of any intellectual property of another's
            data in connection with the use of the Service (ii) User's
            connection to the Services, violation of the Agreement, violation of
            an applicable law, submission, posting, or transmission of User's
            content to the Services, and/or violation of any rights of another.
            Metaphy Labs reserves the right, at our own expense, to assume the
            exclusive defense and control of such disputes, and in any event
            User agrees to cooperate in asserting any available defences
          </p>
          <p className="these-terms-of">&nbsp;</p>
          <p className="these-terms-of">
            In no event will Metaphy Labs have any obligations or liability
            under this section arising from: (i) use of the Service in a
            modified form or in combination with materials not furnished by
            Tomobo.ai; or (ii) any content, information, or data provided by
            Candidates, or other third parties. THIS INDEMNITY IS USER'S ONLY
            REMEDY UNDER THIS USER AGREEMENT FOR ANY VIOLATION BY Metaphy Labs
            OF ANY THIRD PARTY'S INTELLECTUAL PROPERTY RIGHTS
          </p>
          <p className="these-terms-of">&nbsp;</p>
          <p className="these-terms-of">&nbsp;</p>
          <p className="our-services">14. GOVERNING LAW AND JURISDICTION</p>
          <p className="our-services">&nbsp;</p>
          <p className="these-terms-of">
            The jurisdiction of any action arising out of or under these Terms
            shall lie exclusively with the Courts at Mumbai, India
          </p>
          <p className="these-terms-of">&nbsp;</p>
          <p className="these-terms-of">
            The Service is controlled and operated by Metaphy Labs from India,
            and is not intended to subject Metaphy Labs to the laws or
            jurisdiction of any territory other than that of India. Metaphy Labs
            does not represent or warrant that the Service or any part thereof
            is appropriate or available for use in any particular jurisdiction.
            Those who choose to access the Service do so on their own initiative
            and at their own risk, and are responsible for complying with all
            local laws, rules and regulations. Metaphy Labs may limit the
            Service's availability, in whole or in part, to any person,
            geographic area or jurisdiction we choose, at any time and in
            Metaphy Lab’s sole discretion
          </p>
          <p className="these-terms-of">&nbsp;</p>
          <p className="these-terms-of">&nbsp;</p>
          <p className="our-services">15. No Agency; No Employment </p>
          <p className="our-services">&nbsp;</p>
          <p className="these-terms-of">
            No agency, partnership, joint venture, employer-employee or
            franchiser-franchisee relationship is intended or created by this
            Agreement. 
          </p>
          <p className="these-terms-of">&nbsp;</p>
          <p className="these-terms-of">&nbsp;</p>
          <p className="our-services">{`16. General Provisions `}</p>
          <p className="these-terms-of">&nbsp;</p>
          <p className="these-terms-of">
            This Agreement constitutes the complete and exclusive agreement
            between you and Metaphy Labs with respect to its subject matter,
            This agreement is legally binding unless otherwise noted by Metaphy
            Labs. The provisions of this Agreement are intended to be
            interpreted in a manner which makes them valid, legal, and
            enforceable. In the event any provision is found to be partially or
            wholly invalid, illegal or unenforceable, 
          </p>
          <p className="these-terms-of">
            (i) such provision shall be modified or restructured to the extent
            and in the manner necessary to render it valid, legal, and
            enforceable or, 
          </p>
          <p className="these-terms-of">
            (ii) if such provision cannot be so modified or restructured, it
            shall be excised from the Agreement without affecting the validity,
            legality or enforceability of any of the remaining provisions. 
          </p>
          <p className="these-terms-of">
            This Agreement may not be assigned or transferred by you without our
            prior written approval. We may assign or transfer this Agreement
            without your consent, including but not limited to assignments: 
          </p>
          <p className="these-terms-of">(1) to a parent or subsidiary, </p>
          <p className="these-terms-of">(2) to an acquirer of assets, or </p>
          <p className="these-terms-of">
            (3) to any other successor or acquirer. 
          </p>
          <p className="these-terms-of">
            Any assignment in violation of this section shall be null and void. 
          </p>
          <p className="these-terms-of">&nbsp;</p>
          <p className="these-terms-of">&nbsp;</p>
          <p className="our-services">
            17. Changes to this Agreement and the Services 
          </p>
          <p className="our-services">&nbsp;</p>
          <p className="these-terms-of">
            Metaphy Labs reserves the right, at its sole and absolute
            discretion, to change, modify, add to, supplement, suspend,
            discontinue, or delete any of the terms and conditions of this
            Agreement (including these Terms of Service and Privacy Policy) and
            review, improve, modify or discontinue, temporarily or permanently,
            the Services or any content or information through the Services at
            any time, effective with or without prior notice and without any
            liability to Metaphy Labs. Metaphy Labs will endeavor to notify you
            of material changes by email, but will not be liable for any failure
            to do so. If any future changes to this Agreement are unacceptable
            to you or cause you to no longer be in compliance with this
            Agreement, you must terminate, and immediately stop using, the
            Services. Your continued use of the service after any and all
            modifications represents your acceptance of the agreement. In
            addition, Metaphy Labs may place restrictions on your use of
            specific features or limit your access to all or a portion of the
            services. 
          </p>
          <p className="these-terms-of">&nbsp;</p>
          <p className="our-services">18. No Rights of Third Parties</p>
          <p className="our-services">&nbsp;</p>
          <p className="these-terms-of">
             None of the terms of this Agreement are enforceable by any persons
            who are not a party to this Agreement. 
          </p>
          <p className="these-terms-of">&nbsp;</p>
          <p className="these-terms-of">&nbsp;</p>
          <p className="our-services">
            19. Notices and Consent to Receive Notices Electronically 
          </p>
          <p className="our-services">&nbsp;</p>
          <p className="these-terms-of">
            You consent to receive any agreements, notices, disclosures and
            other communications (collectively, “ Notices”) to which this
            Agreement refers electronically including without limitation by
            email or by posting Notices on this Site. You agree that all Notices
            that we provide to you electronically satisfy any legal r equirement
            that such communications be in writing. Unless otherwise specified
            in this Agreement, all notices under this Agreement will be in
            writing and will be deemed to have been duly given when received, if
            personally delivered or sent by certified or registered mail, return
            receipt requested; when receipt is electronically confirmed, if
            transmitted by facsimile or email; or the day after it is sent, if
            sent for next day delivery by a recognized overnight delivery
            service. 
          </p>
          <p className="these-terms-of">&nbsp;</p>
          <p className="these-terms-of">&nbsp;</p>
          <p className="our-services">20. Contacting Us </p>
          <p className="our-services">&nbsp;</p>
          <p className="these-terms-of">
            If you have any questions about these Terms of Service or about the
            Services, please contact us by email at support@tomobo.ai.
          </p>
        </span>
      </div>
    </div>
  );
};

export default TermsAndConditions;
