import React from "react";

function Error() {
  return (
    <div>
      <img src="./Logo.png" />
      <div>
        <h1>Uh! Ohh! seemd you are Unauthorized</h1>
      </div>
      <img />
      <button>Reach us here</button>
    </div>
  );
}

export default Error;
