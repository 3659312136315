import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import "./css/CSat.css";
import TextField from "../components/textfield";

function CSat() {
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState("");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const userId = searchParams.get("userId");
  console.log(userId)
  const meetingId = searchParams.get("meetingId")
  console.log(meetingId)

  const navigate = useNavigate();

  const handleRating = (rate) => {
    setRating(rate);
  };

  const onPointerEnter = () => console.log("Enter");
  const onPointerLeave = () => console.log("Leave");
  const onPointerMove = (value, index) => console.log(value, index);

  const handleTextChange = (value) => {
    setFeedback(value);
  };

  const handleSubmit = () => {
    if (rating === 0 ) {
      alert("Please provide your rating", {
        position: "top",
      });
      return;
    }

    const feedbackData = {
      userId,
      meetingId,
      rating,
      feedback,
    };
    console.log(feedbackData);

    fetch("https://app.metaphy.ai/salescopilot/csat/feedback", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(feedbackData),
    })
      .then((response) => response.json())
      .then((responseData) => {
        console.log("Response from backend:", responseData);
        navigate("/greet");
      })
      .catch((error) => {
        console.error("Error submitting data:", error);
      });
  };

  return (
    <div className="container-csat">
      <img src="/Logo.png" />
      <div className="container-csat-child">
        <h1>We would love to know your experience!</h1>
        <div className="rating">        <Rating
          onClick={handleRating}
          onPointerEnter={onPointerEnter}
          onPointerLeave={onPointerLeave}
          onPointerMove={onPointerMove}
          size={100}
          fillColor="#6758FF"
        /></div>

        <h2>
          Your feedback powers our A-team of product managers and CX wizards,
          creating an extraordinary experience for your remarkable team ❤️
        </h2>

        <TextField onTextChange={handleTextChange} />
        <button
          disabled={rating === 0 }
          onClick={handleSubmit}
        >
          {" "}
          Submit Feedback
        </button>
      </div>
    </div>
  );
}

export default CSat;
