import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { isUserAuthenticatedState } from "../atom";
import { getGoogleUrl, handleLogin } from "../utils/auth";
import "./css/Landing.css";

function Landing() {
  const [isChecked, setIsChecked] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const accessToken = urlParams.get("accessToken");
    if (accessToken !== null) {
      localStorage.setItem("token", accessToken);
      handleLogin(accessToken);
      setIsUserAuthenticated(true);
      navigate("/");
    }
  }, []);

  const [isUserAuthenticated, setIsUserAuthenticated] = useRecoilState(
    isUserAuthenticatedState,
  );

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const redirectToGoogle = async () => {
    try {
      const googleUrl = await getGoogleUrl();
      window.location.href = googleUrl;
    } catch (error) {
      console.log("Failed to get Google URL:", error);
      // Handle the error if needed
    }
  };

  const redirectToMicrosoft = () => {
    window.location.href =
      "https://app.metaphy.ai/salescopmicro/auth/microsoft";
    // window.location.href = "https://localhost:5500/auth/microsoft";
  };

  return (
    <div className="main">
      <div className="container-split">
        <div className="container-split-right">
          <img src="./illustration1.png" />
        </div>
        <div className="container-split-left">
          <div className="content">
            <img src="Logo.png" />
            <div>
              <h1>
                Don't take notes during your{" "}
                <span className="meeting">Meeting</span>
              </h1>
              <p>
                Yup, we said that! ToMoBo joins your meetings and sends you the
                summary and action items of the call post the meeting. Trust us,
                it works! ❤️
              </p>
              <p>
                It's quick and easy to get started. All you got to do is connect
                your calendar to our tool and we are good to go!{" "}
              </p>
            </div>
            <div className="container-checkbox">
              <input
                type="checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <p>
                &nbsp;&nbsp;&nbsp;I agree with{" "}
                <a
                  className="span-links"
                  href="/tnc"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms Of Service
                </a>{" "}
                and{" "}
                <a
                  className="span-links"
                  href="/pp"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
              </p>
            </div>
            <div className="socialButtons">
              <button
                className={`buttons ${
                  !isChecked
                    ? "cursor-not-allowed opacity-50"
                    : "cursor-pointer"
                }`}
                onClick={redirectToGoogle}
                disabled={!isChecked}
              >
                <img src="./iconsgoogle.svg" />
                Continue with Google
              </button>
              <button
                className={`buttons ${
                  !isChecked
                    ? "cursor-not-allowed opacity-50"
                    : "cursor-pointer"
                }`}
                onClick={redirectToMicrosoft}
                disabled={!isChecked}
              >
                <img src="./iconsmicrosoft.svg" />
                Continue with Microsoft
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Landing;
